import React from 'react'

import { NavLink } from 'react-router-dom'

import Grid from '@mui/material/Grid2'

import { Routes } from '../../Config/Routes'

import './header.css'

const Header = () => {
	return (
		<header className='app-header'>
			<Grid container className='header-container'>
				<Grid item size={2} className='logo-container'>
					<img src='https://placehold.co/120x40' alt='logo' />
				</Grid>
				<Grid item size={10}>
					<nav className='nav-container'>
						{Routes.map((route) => (
							<NavLink key={route.path} to={route.path} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
								{route.name}
							</NavLink>
						))}
					</nav>
				</Grid>
			</Grid>
		</header>
	)
}

export default Header
