import React from 'react'
import ListingPage from '../pages/Home/ListingPage'
import Form from '../pages/Form/Form'
import Popup from '../pages/Popup/Popup'

export const Routes = [
	{
		element: <ListingPage />,
		path: '/',
		name:'Listing'
	},
	{
		element: <Form />,
		path: '/form',
		name:'Form'
	},
	{
		element: <Popup />,
		path: '/Popup',
		name:'Popup'
	}
]
