import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './pageLayout.css'

const Layout = ({ children }) => {
	return (
		<div className='layout'>
			<Header></Header>
			<div className='page-wrapper'>
				<main className='page-container'>{children}</main>
				<Footer></Footer>
			</div>
		</div>
	)
}

export default Layout
