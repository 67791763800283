import React from 'react';
import { createBrowserRouter,RouterProvider } from "react-router-dom";

import { Routes } from './Config/Routes';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const router = createBrowserRouter(Routes)

  return <RouterProvider router={router} />;
}

export default App;
