import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid2'
import { Chip, Card, CardActions, CardContent, CardMedia, Button, Pagination } from '@mui/material'

import './listing.css'

const Listing = ({ items, itemsForPage }) => {
	const [listingItems, setListingItems] = useState(items || [])
	const [backupItems, setBackupItems] = useState(items || [])
	const [search, setSearch] = useState('')
	const [currPage, setCurrPage] = useState(1)

	useEffect(() => {
		if (listingItems.length < 1) {
			setListingItems(items)
			setBackupItems(items)
		}
	}, [items])

	useEffect(() => {
		searchItems(search)
	}, [backupItems])

	const applyFilter = (value) => {
		let newItems = items

		newItems = newItems.filter((item) => {
			if (value === 'all') {
				return true
			} else {
				return item.type === value
			}
		})

		setBackupItems(newItems)
	}

	const searchItems = (value) => {
		let newItems = backupItems

		if (value.length > 0) {
			newItems = backupItems.filter((item) => {
				const res = item.title.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase())

				return res
			})
		}

		setSearch(value)
		setListingItems(newItems)

		return newItems
	}

	const getRenderItems = () => {
		let renderItem = listingItems

		if (itemsForPage) {
			const startIndex = (currPage - 1) * itemsForPage
			const endIndex = currPage * itemsForPage

			renderItem = listingItems.slice(startIndex, endIndex)
		}

		return renderItem
	}

	return (
		<div className='listing-container'>
			<div className='filter-row'>
				<div className='input'>
					<input type='text' placeholder='Search' onChange={(e) => searchItems(e.target.value)} />
				</div>
				<div className='filter'>
					<span className='pe-2'>Type</span>
					<select
						onChange={(e) => {
							applyFilter(e.target.value)
							searchItems(search)
						}}>
						<option value='all'>All</option>
						<option value='news'>News</option>
						<option value='prodotti'>Prodotti</option>
						<option value='altro'>Altro</option>
					</select>
				</div>
			</div>
			<Grid container spacing={2} columns={12}>
				{getRenderItems().map((item, i) => (
					<Grid item size={{ xs: 12, md: 6, lg: 4, xl: 3 }} key={item.id || `listing-item-${i}`} className='listing-item position-relative'>
						<Card className='h-100'>
							<Chip className='chip' label={item.type} />
							<CardMedia component='img' height='140' image={item.image} alt='img' />
							<CardContent>
								<h2>{item.title}</h2>
								<p>{item.description}</p>
							</CardContent>
							<CardActions>
								<Button size='small' onClick={() => item.cta.action()}>
									{item.cta.label}
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
				{listingItems.length < 1 ? <div>Nessun risultato</div> : null}
			</Grid>
			{itemsForPage && listingItems.length > itemsForPage ? (
				<div className='d-flex justify-content-center p-4'>
					<Pagination count={Math.ceil(listingItems.length / itemsForPage)} shape='rounded' onChange={(e, page) => setCurrPage(page)} />
				</div>
			) : null}
		</div>
	)
}

export default Listing
