import React, { useState } from 'react'
import Layout from '../../Layout/PageLayout/PageLayout'

import { FormControl, useFormControlContext } from '@mui/base/FormControl'
import { styled } from '@mui/system'

import { Button } from '@mui/material'

import clsx from 'clsx'

const Form = () => {
	const [form, setForm] = useState({
		name: '',
		surname: '',
		checkbox: false
	})

	const submit = () => {
		console.log('☠️ --> submit --> form:', form)

		fetch('https://jsonplaceholder.typicode.com/posts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(form)
		})
	}

	return (
		<Layout>
			<h1>Form</h1>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
				<FormControl defaultValue='' required>
					<Label>Name</Label>
					<input value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} placeholder='Write your name here' />
					<HelperText />
				</FormControl>
				<FormControl defaultValue='' required>
					<Label>Surname</Label>
					<input
						value={form.surname}
						onChange={(e) => setForm({ ...form, surname: e.target.value })}
						placeholder='Write your surname here'
					/>
					<HelperText />
				</FormControl>

				<FormControl defaultValue='' required>
					<div className='d-flex align-items-center'>
						<Label>Checkbox</Label>
						<input checked={form.checkbox} onChange={(e) => setForm({ ...form, checkbox: e.target.value })} type='checkbox' />
						<HelperText />
					</div>
				</FormControl>

				<div className='d-flex align-items-center justify-content-center'>
					<Button type='submit' onClick={() => setForm({ name: '', surname: '', checkbox: false })}>
						Reset
					</Button>
					<Button type='submit' onClick={() => submit()}>
						Submit
					</Button>
				</div>
			</div>
		</Layout>
	)
}

export default Form

const Label = styled(({ children, className }) => {
	const formControlContext = useFormControlContext()
	const [dirty, setDirty] = React.useState(false)

	React.useEffect(() => {
		if (formControlContext?.filled) {
			setDirty(true)
		}
	}, [formControlContext])

	if (formControlContext === undefined) {
		return <p className=''>{children}</p>
	}

	const { error, required, filled } = formControlContext
	const showRequiredError = dirty && required && !filled

	return (
		<p style={{ margin: 0 }} className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
			{children}
			{required ? ' *' : ''}
		</p>
	)
})`
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 0.875rem;
	margin-bottom: 4px;

	&.invalid {
		color: red;
	}
`

const HelperText = styled((props) => {
	const formControlContext = useFormControlContext()
	const [dirty, setDirty] = React.useState(false)

	React.useEffect(() => {
		if (formControlContext?.filled) {
			setDirty(true)
		}
	}, [formControlContext])

	if (formControlContext === undefined) {
		return null
	}

	const { required, filled } = formControlContext
	const showRequiredError = dirty && required && !filled

	return showRequiredError ? <p {...props}>This field is required.</p> : null
})`
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 0.875rem;
`
