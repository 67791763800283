import React, { useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'

import Layout from '../../Layout/PageLayout/PageLayout'

const Popup = () => {
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

	return (
		<Layout>
			<h1>Popup</h1>
            <div className='d-flex justify-content-center align-items-center'>
			<Button onClick={() => handleOpen()}>Open modal</Button>
            </div>
			<Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Text in a modal
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
					</Typography>
				</Box>
			</Modal>
		</Layout>
	)
}

export default Popup
