import React from 'react'

import { NavLink } from 'react-router-dom'

import Grid from '@mui/material/Grid2'

import { Routes } from '../../Config/Routes'

import './footer.css'

const Footer = () => {
	return (
		<footer className='app-footer'>
			<Grid container>
				<Grid item xs={6} className='logo-container'>
					<img src='https://placehold.co/120x40' alt='logo' />
				</Grid>
				<Grid item xs={10} className='nav-container'>
					<nav>
						<ul>
							{Routes.map((route) => (
								<li key={route.path}>
									<NavLink  to={route.path} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
										{route.name.toUpperCase()}
									</NavLink>
								</li>
							))}
						</ul>
					</nav>
				</Grid>
			</Grid>
		</footer>
	)
}

export default Footer
