import React, { useEffect, useState } from 'react'

import Layout from '../../Layout/PageLayout/PageLayout'
import Listing from '../../Components/Listing/Listing'

const ListingPage = () => {
	const listingItems = [
		{
			title: 'Item 1',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'news'
		},
		{
			title: 'Item 2',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'news'
		},
		{
			title: 'Item 3',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'prodotti'
		},
		{
			title: 'Item 4',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'prodotti'
		},
		{
			title: 'Item 5',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'altro'
		},
		{
			title: 'Item 6',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'altro'
		},
		{
			title: 'Item 7',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'news'
		},
		{
			title: 'Item 8',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'news'
		},
		{
			title: 'Item 9',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'prodotti'
		},
		{
			title: 'Item 10',
			image: 'https://placehold.co/140x140',
			description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, quidem.',
			cta: { label: 'View', action: () => {} },
			type: 'altro'
		}
	]
	const [apiItems, setApiItems] = useState([])

	useEffect(() => {
		fetch('https://jsonplaceholder.typicode.com/posts')
			.then(response => response.json())
			.then(data => {
				const res = data.map(item => ({
					title: item.title,
					image: 'https://placehold.co/140x140',
					description: item.body,
					cta: { label: 'View', action: () => {} },
					type: ['news', 'prodotti', 'altro'][Math.floor(Math.random() * 3)]
				}))
				
				console.log('res', res)
				setApiItems(res)
				return res
			})
			.catch(error => console.error(error))
	}, [])

	useEffect(() => {
		console.log('apiItems', apiItems)
	}, [apiItems])

	return (
		<Layout>
			<h1>Listing</h1>
			<Listing items={apiItems} itemsForPage={4} />
		</Layout>
	)
}

export default ListingPage
